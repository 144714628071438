import clsx from "clsx";
import EntryThumbnail from "components/EntryThumbnail";
import dayjs from "dayjs";
import React from "react";

import { getEntryCategoryArray } from "@lib/getEntryCategoryArray";
import { getSummaryFromHtml } from "@lib/getSummaryFromHtml";

interface IProps {
	entries?: any[];
	indentMobile?: boolean;
	showDate?: boolean;
	showAuthor?: boolean;
	thumbnailAspectRatio?: string;
	filterBySection?: string;
	columns?: number;
	itemsToDisplay?: number;
}

const EntryGrid = ({
	entries,
	indentMobile = false,
	showDate = false,
	showAuthor = true,
	thumbnailAspectRatio = "landscape",
	columns = 3,
	itemsToDisplay = 8,
}: IProps): JSX.Element => {
	return (
		<div className={clsx("EntryGrid", "mx-auto")}>
			<div
				className={clsx(
					"EntryGrid__entries",
					"grid grid-cols-1 md:grid-cols-2  gap-15",
					{
						"xl:grid-cols-3": columns === 3 || itemsToDisplay === 3,
						"xl:grid-cols-4": columns === 4 && itemsToDisplay !== 3,
					}
				)}
			>
				{entries?.map((entry, index) => {
					const image = !!entry.image ? entry.image[0] : null;
					const imageBlur = !!entry.imageBlur ? entry.imageBlur[0] : null;

					// Gather all possible categories from the entry
					const categories = getEntryCategoryArray(entry);

					// Check if there is a summary, otherwise we'll have to get it from teh body
					if (!entry.summary) {
						entry.summary = getSummaryFromHtml(entry.legacyBlogContent);
					}

					const parsedDate = dayjs(entry.postDate);

					const targetImage = image;

					return (
						<div key={entry.id} className="EntryGrid__entryCard bg-white-01">
							<EntryThumbnail
								categories={categories}
								entry={entry}
								image={targetImage}
								size={"md"}
								imageBlur={imageBlur}
								heading={entry.title}
								summary={entry.summary}
								target={{ element: entry, type: "entry" }}
								authors={showAuthor && entry.postAuthors}
								externalUrl={entry.externalUrl}
								indentMobile={indentMobile}
								date={showDate ? parsedDate.format("MMMM D, YYYY") : undefined}
								aspectRatio={thumbnailAspectRatio}
								source={entry.publication}
								measureImageHeight={false}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default EntryGrid;
