import clsx from "clsx";

import AuthorsPlus from "@components/AuthorsPlus";
import CategoryTokens from "@components/CategoryTokens";
import Target from "@components/Target";

const EntryThumbnailHeader = ({
	heading = "",
	categories = [],
	target,
	authors = [],
	size = "md",
	indentMobile = false,
	date = null,
	source = null,
}) => {
	const hasCategories = !!categories && categories.length > 0;

	return (
		<div className={clsx("EntryThumbnailHeader")}>
			{hasCategories && (
				<div
					className={clsx("EntryThumbnailHeader__categories", "mb-7.5", {
						"px-3 md:pl-0": indentMobile,
					})}
				>
					<CategoryTokens categories={categories} />
				</div>
			)}
			<div className={clsx("EntryThumbnailHeader__headingWrapper", "mb-7.5")}>
				<div className={clsx("EntryThumbnailHeader__headingAuthors")}>
					<Target
						target={target}
						className={clsx(
							"EntryThumbnailHeader__heading",
							"max-w-lg",
							"pr-3 md:pr-7.5 md:pl-0",
							"block",
							"hover-underline",
							"line-clamp-3",
							"font-medium",
							{
								"px-3  md:px-7.5 md:pl-0": indentMobile,
								"text-xl lg:text-2xl": size === "lg",
								"text-xl": size === "md",
								"text-lg": size === "sm",
							}
						)}
					>
						<span className={clsx("inline-block pb-[3px]")}>{heading}</span>
					</Target>
					{(!!date || !!source) && (
						<div
							className={clsx(
								"EntryThumbnailHeader__meta",
								"pt-3 text-xs",
								"flex",
								"space-x-4",
								{
									"px-7.5 md:pl-0": indentMobile,
								}
							)}
						>
							{!!date && (
								<div className={clsx("EntryThumbnailHeader__date")}>{date}</div>
							)}
							{!!source && (
								<div
									className={clsx(
										"EntryThumbnailHeader__source",
										"text-black-01/75"
									)}
								>
									{source}
								</div>
							)}
						</div>
					)}
					<div
						className={clsx("EntryThumbnailHeader__authors", {
							"px-7.5 md:pl-0": indentMobile,
						})}
					>
						{!!authors && <AuthorsPlus authors={authors} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default EntryThumbnailHeader;
