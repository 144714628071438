import clsx from "clsx";

interface IProps {
	children: any;
	tag: "h1" | "h2" | "h3" | "h4";
	className?: string;
	style?: object;
	onClick?: any;
}

const HeadingTag = ({
	children,
	tag = "h2",
	className = "",
	style = {},
	onClick = () => null,
}: IProps): JSX.Element => {
	const Wrapper = () => {
		switch (tag) {
			case "h1": {
				return (
					<h1
						className={clsx("font-medium leading-normal", className)}
						style={style}
						onClick={onClick}
					>
						{children}
					</h1>
				);
			}
			case "h2": {
				return (
					<h2
						className={clsx("font-medium leading-normal", className)}
						style={style}
						onClick={onClick}
					>
						{children}
					</h2>
				);
			}
			case "h3": {
				return (
					<h3
						className={clsx("font-medium leading-normal", className)}
						style={style}
						onClick={onClick}
					>
						{children}
					</h3>
				);
			}
			case "h4": {
				return (
					<h4
						className={clsx("font-medium leading-normal", className)}
						style={style}
						onClick={onClick}
					>
						{children}
					</h4>
				);
			}
			default: {
				return (
					<h2
						className={clsx("font-medium leading-normal", className)}
						style={style}
						onClick={onClick}
					>
						{children}
					</h2>
				);
			}
		}
	};

	return <Wrapper />;
};

export default HeadingTag;
